<template>
    <yh-page fullscreen>
        <div class="goods-page">


            <div class="left-filter" :class="{'expand':expand.filter}">

                <button class="filter-expand-btn" @click="expand.filter = !expand.filter">
                    <span class="iconfont pc-zhankai closed"></span>
                    <span class="iconfont pc-shouqi opened"></span>
                </button>

                <perfect-scrollbar class="left-filter-scroll" :options="{suppressScrollX:false}">

                    <div class="left-filter-item " :class="{'expand':expand.plate}">
                        <div class="filter-name">来源
                            <span class="icon" @click="expand.plate = !expand.plate">
                            <span class="closed iconfont pc-xiajiantou"></span>
                            <span class="opened iconfont pc-shangjiantou"></span>
                        </span>
                        </div>
                        <div class="filter-content">
                            <template v-for="(item,k) in plates">
                                <div class="filter-row" :key="k">
                                    <check-box name="plate" :value="item" :checked="params.plate === item.value "
                                               @change="checkBoxSelected">
                                        <span v-if="item.icon" class="iconfont" :class="item.icon"></span>{{ item.name
                                        }}
                                    </check-box>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="left-filter-item" :class="{'expand':expand.category}">
                        <div class="filter-name">分类
                            <span class="icon" @click="expand.category = !expand.category">
                                <span class="closed iconfont pc-xiajiantou"></span>
                                <span class="opened iconfont pc-shangjiantou"></span>
                            </span>
                        </div>
                        <div class="filter-content">
                            <template v-for="(cate,k) in categories">
                                <div class="filter-row" :key="k">

                                    <div v-if="cate.children && cate.children.length" @click="expandObj(cate)"
                                         class="cate-expand-btn">
                                        <span v-if="cate.expand" class="iconfont pc-sanjiao-xiangxia"></span>
                                        <span v-else class="iconfont pc-sanjiao-xiangyou"></span>
                                    </div>

                                    <check-box name="plate" value="vip" :checked="params.cate === cate.id "
                                               @change="cateClicked(cate)">{{ cate.name }}
                                    </check-box>

                                    <div v-if="cate.expand" class="filter-sub-rows">
                                        <template v-for="(item,ck) in cate.children">

                                            <div class="filter-row" :key="ck">

                                                <div v-if="item.children && item.children.length"
                                                     @click="expandObj(item)" class="cate-expand-btn">
                                                    <span v-if="item.expand"
                                                          class="iconfont pc-sanjiao-xiangxia"></span>
                                                    <span v-else class="iconfont pc-sanjiao-xiangyou"></span>
                                                </div>

                                                <check-box name="plate" value="vip" :checked="params.cate === item.id "
                                                           @change="cateClicked(item)">{{ item.name }}
                                                </check-box>


                                                <div v-if="item.expand" class="filter-sub-rows">

                                                    <template v-for="(child,dk) in item.children">

                                                        <div class="filter-row" :key="dk">

                                                            <check-box name="plate" value="vip"
                                                                       :checked="params.cate === child.id "
                                                                       @change="cateClicked(child)">{{ child.name }}
                                                            </check-box>

                                                        </div>

                                                    </template>

                                                </div>

                                            </div>

                                        </template>
                                    </div>

                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="left-filter-item" :class="{'expand':expand.price}">
                        <div class="filter-name">价格
                            <span class="icon" @click="expand.price = !expand.price">
                            <span class="closed iconfont pc-xiajiantou"></span>
                            <span class="opened iconfont pc-shangjiantou"></span>
                        </span>
                        </div>
                        <div class="filter-content">
                            <template v-for="(item,k) in prices">
                                <div class="filter-row" :key="k">
                                    <check-box name="price" :value="item" :checked="params.price === item.value "
                                               @change="checkBoxSelected">
                                        <span v-if="item.icon" class="iconfont" :class="item.icon"></span>{{ item.name
                                        }}
                                    </check-box>
                                </div>
                            </template>
                        </div>
                    </div>

                </perfect-scrollbar>

            </div>

            <div class="left-filter-empty" :class="{'expand':expand.filter}"></div>

            <perfect-scrollbar class="goods-content" :options="{suppressScrollX:false}" @ps-y-reach-end="loadNextPage">
                <div class="search">
                    <input class="search-input" v-model="keywords" type="text" placeholder="搜索您感兴趣的单品"/>
                    <button @click="search" class="btn">搜索</button>
                </div>

                <div class="list-filter">

                    <div class="selected-list">
                        <div v-if="selected.plate">{{ selected.plate }}
                            <span @click="clearPlate" class="iconfont pc-guanbi"></span>
                        </div>
                        <div v-if="selected.category">{{ selected.category.name }}
                            <span class="iconfont pc-guanbi" @click="clearCate"></span>
                        </div>
                        <div v-if="selected.price">{{ selected.price }}
                            <span @click="clearPrice" class="iconfont pc-guanbi"></span>
                        </div>
                    </div>


                    <div class="sorts">
                        <template v-for="(label,slug) in sorts">
                            <button :key="slug"
                                    :class="{'selected' : params.order_by === slug }"
                                    @click="sortClicked(slug)">{{ label }}
                                <template v-if=" slug === 'price' ">

                                    <template v-if="params.order_by === 'price' ">
                                        <span v-if="params.order_type === 'asc' " class="iconfont pc-shang"></span>
                                        <span v-else class="iconfont pc-xia"></span>
                                    </template>
                                    <span v-else class="iconfont pc-weixuan"></span>
                                </template>
                            </button>
                        </template>
                    </div>

                </div>


                <div class="goods-list">

                    <template v-for="(item,k) in goods">
                        <div :key="k" class="goods-item">
                            <goods-item :goods="item" hidden-comm></goods-item>
                        </div>
                    </template>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>

                    <div v-if="!loading && !goods.length" class="empty">
                        暂无单品数据哦
                    </div>
                    <div v-else-if="!loading && !hasMoreGoods" class="empty">
                        到底咯....
                    </div>
                    <div v-else-if="loading" class="empty">
                        加载中...
                    </div>

                </div>

            </perfect-scrollbar>
        </div>
    </yh-page>
</template>

<script>
    import CheckBox from "@/components/CheckBox";
    import api from "@/repo/api";
    import YhPage from "../../../layout/YhPage";
    import GoodsItem from "../../../components/GoodsItem";
    import auth from "../../../auth";

    export default {
        name: "GoodsFilter",
        components: {GoodsItem, YhPage, CheckBox},
        data() {
            return {
                goods: [],
                params: {
                    keywords: '',
                    gender: auth.gender(),
                    cate: 0,
                    order_by: '',
                    order_type: '',
                    plate: '',
                    price: '',
                    page: 1,
                },
                keywords: '',
                categories: [],
                sorts: {
                    'normal': '综合',
                    'hot': '热度',
                    'new': '新品',
                    'price': '价格',
                },
                prices: [
                    {
                        name: '0~99',
                        value: '0-99',
                    }, {
                        name: '100~199',
                        value: '100-199',
                    }, {
                        name: '200~399',
                        value: '200-399',
                    }, {
                        name: '400以上',
                        value: '400-0',
                    }
                ],
                plates: [
                    {
                        name: '淘宝',
                        value: 'tb',
                        icon: 'pc-taobao',
                    },
                    {
                        name: '京东',
                        value: 'jd',
                        icon: 'pc-jingdong',
                    },
                    {
                        name: '唯品会',
                        value: 'vip',
                        icon: 'pc-weipinhui',
                    }
                ],
                loading: true,
                hasMoreGoods: true,
                originCategories: [],
                expand: {
                    plate: true,
                    category: true,
                    price: true,
                    filter: true,
                },
                selected: {
                    category: null,
                    price: null,
                    plate: null,
                }
            }
        },
        mounted() {
            this.getCategories()
            this.getGoods()
        },
        methods: {
            search() {
                this.params.keywords = this.keywords

                this.loadFirstPage();
            },
            loadFirstPage() {
                this.params.page = 1
                this.hasMoreGoods = true
                this.goods = []
                this.getGoods()
            },
            loadNextPage() {
                if (!this.hasMoreGoods || this.loading) return

                this.params.page++;
                this.getGoods()
            },
            getGoods() {
                this.loading = true
                api.getGoods(this.params, (data) => {
                    let goods = data.data.data

                    this.loading = false

                    this.hasMoreGoods = goods.length === data.data.per_page

                    if (goods.length === 0) {
                        return
                    }

                    this.goods = this.goods.concat(goods)
                })
            },
            cateClicked(cate) {

                this.params.cate = (this.params.cate === cate.id) ? '' : cate.id

                this.selected.category = this.params.cate ? cate : null;

                this.loadFirstPage();
            },
            checkBoxSelected(name, value, selected) {

                this.params[name] = selected ? value.value : '';

                this.selected[name] = selected ? value.name : null;

                this.loadFirstPage();
            },
            getCategories() {
                api.getCategories((data) => {

                    this.originCategories = data.data
                    this.pickGenderCategory()

                })
            },
            pickGenderCategory() {
                let arr = []

                this.originCategories.forEach(item => {
                    if (item.gender === this.params.gender) arr.push(item)
                })

                this.categories = arr
            },
            sortClicked(slug) {

                let orderType = 'desc';

                if (slug === 'price' && this.params.order_by === slug) {
                    orderType = this.params.order_type === 'desc' ? 'asc' : 'desc';
                }

                this.params.order_type = orderType;
                this.params.order_by = slug;

                this.loadFirstPage();
            },
            expandObj(obj) {
                obj.expand = !obj.expand
                this.$forceUpdate()
            },
            clearCate() {
                this.selected.category = null
                this.params.cate = ''

                this.loadFirstPage();
            },
            clearPlate() {
                this.selected.plate = null
                this.params.plate = ''

                this.loadFirstPage();
            },
            clearPrice() {
                this.selected.price = null
                this.params.price = ''

                this.loadFirstPage();
            },
        }
    }
</script>

<style lang="less" scoped>
    .search {
        max-width: 670px;
        display: flex;
        flex-direction: row;
        margin: 0 auto 40px;

        input, button {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        input {
            flex: 1;
            padding: 8px 16px;
            outline: none;
            border: 1px solid rgba(51, 51, 51, 0.8);
        }

        button {
            width: 70px;
            padding: 8px;
            border: none;
        }
    }

    .categories {
        margin-top: 56px;
        margin-bottom: 56px;

        button {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .list-filter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 32px;

    }

    .sorts {
        display: flex;
        height: 33px;

        button {

            background-color: transparent;
            border: none;
            margin-right: 48px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            cursor: pointer;

            &.selected {
                font-weight: bold;
                color: rgba(0, 0, 0, 0.8);
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .iconfont {
            font-size: 14px;
        }
    }

    .filter-content .iconfont {
        margin-right: 5px;
    }

    .search-input::placeholder {
        color: #CCC;
    }

    .goods-page {
        /*padding: 128px 64px 0;*/
        display: flex;
    }

    .left-filter-empty {
        transition: all .3s;
        width: 0;

        &.expand {
            width: 335px;
        }
    }

    .left-filter {
        transition: all .3s;

        width: 335px;
        height: 100vh;

        position: fixed;
        z-index: 2;
        top: 0;
        left: -335px;


        .closed {
            display: inline-block;
        }

        .opened {
            display: none;
        }

        &.expand {
            left: 0;

            .closed {
                display: none;
            }

            .opened {
                display: inline-block;
            }
        }
    }

    .left-filter-scroll {
        padding: 128px 32px 0 64px;
        border-right: 1px solid #EEEEEE;
        height: 100vh;
    }

    .goods-page {
        height: 100vh;
        overflow: hidden;
    }

    .goods-content {
        padding: 128px 64px 0;
    }

    .goods-content {
        flex: 1;
    }

    .left-filter-item {
        margin-bottom: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid #EEEEEE;
        overflow: hidden;
        position: relative;

        &:last-child {
            border-bottom: none;
        }

        .filter-name {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
        }

        .icon {
            float: right;
            cursor: pointer;

            .iconfont {
                font-size: 14px;
            }
        }

        .filter-content {
            height: 0;
            overflow: hidden;
            transition: height .3s;
        }

        .closed {
            display: inline-block!important;
        }

        .opened {
            display: none!important;
        }

        &.expand {
            .filter-content {
                height: auto;
            }

            .closed {
                display: none!important;
            }

            .opened {
                display: inline-block!important;
            }

            .filter-name {
                margin-bottom: 15px;
            }
        }
    }

    .filter-sub-rows {
        margin-top: 16px;
        padding-left: 22px;
        overflow: hidden;

        .filter-row {
            margin-bottom: 10px;
        }
    }

    .filter-row {
        overflow: hidden;
        margin-bottom: 16px;
        flex-direction: row;

        /deep/ span {
            color: #999999;
        }
    }

    .filter-expand-btn {
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        position: absolute;
        right: -20px;
        top: 128px;
        z-index: 1;
        border-radius: 50%;
        cursor: pointer;
        font-size: 16px;
    }

    .cate-expand-btn {
        float: right;
        cursor: pointer;

        span {
            font-size: 12px;
        }
    }

    .selected-list {
        display: flex;

        div {
            margin-right: 15px;
            background: #F5F5F5;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
            padding-left: 12px;
            padding-right: 12px;
        }

        .iconfont {
            font-size: 12px;
            cursor: pointer;
        }
    }

    .goods-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;
        min-height: 500px;

        .empty {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            line-height: 24px;
            color: #333333;

            img {
                width: 48px;
                height: 48px;
            }
        }
    }

    .goods-item {
        width: 304px;
        margin-bottom: 32px;

        /deep/ .goods-item {

            height: 368px;

            .image-container {
                width: 304px;
                height: 304px;
            }
        }
    }

</style>